<template>
  <section class="mobile-menu overflow-hidden">
    <nav>
      <h1 class="mobile-menu__name subheadline-small">
        {{ identityName }}
      </h1>

      <div
        v-for="(item) in mobileItems"
        :key="item.label">
        <button
          class="mobile-menu__button w-full"
          :data-cy="'mobile-menu-' + item.name"
          :data-testid="'mobile-menu-' + item.name"
          @click="() => handleAction(item)">
          <div class="mobile-menu__option">
            <span class="text-left subheadline-medium">{{ item.label }}</span>
            <div v-if="item.description" class="text-x-large">{{ item.description }}</div>
          </div>
          <ChevronLeftIcon class="mobile-menu__icon h-5 xs:h-3 rotate-180" />
        </button>
      </div>

      <section class="mobile-menu__theme">
        <h1 class="subheadline-medium mr-s12">{{ $t('navigation.theme') }}:</h1>
        <div
          @click="switchTheme('dark')"
          :class="`theme-circle ${currentTheme === 'dark' ? 'border-2 bg-background-page' : 'bg-text-body'}`"
        ></div>
        <div
          @click="switchTheme('light')"
          :class="`ml-2 theme-circle ${currentTheme === 'light' ? 'border-2 bg-background-page' : 'bg-text-body'}`"
        ></div>
      </section>
    </nav>

    <footer class="mobile-menu__social">
      <h5 class="body-text-x-large">
        {{ $t('footer.join_our_community_section.title') }}
      </h5>
      <div class="mobile-menu__social-icons">
        <IconButton
          v-for="item in icons"
          :key="item.type"
          class="text-n-3xl"
          icon-style="fab"
          :icon="item.type"
          variation="plain"
          @onClick="goToExternalPageGlobal(item.to)"
        />
      </div>
    </footer>
  </section>
</template>

<script>
import ChevronLeftIcon from '@/assets/icons/chevron_left.svg';
import IconButton from '@/components/misc/IconButton/IconButton';

import Menu from '@/components/navbar/Menu.js';

export default {
  name: 'MobileMenu',
  extends: Menu,
  components: {
    ChevronLeftIcon,
    IconButton,
  },
  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'ACCOUNT_VIEWED',
    });
  },
  computed: {
    identityName() {
      const userInfo = this.lodashGet(this.$store.state.api, 'current_user.user_info', { name: '', email: '' });
      return userInfo.email || userInfo.name || userInfo.email.split('@')[0];
    },
    icons() {
      return [
        {
          type: 'discord',
          to: 'https://discord.gg/bUwf3wF8ZM',
        },
        {
          type: 'twitter',
          to: 'https://twitter.com/dibbs_io',
        },
        {
          type: 'instagram',
          to: 'https://www.instagram.com/dibbs.io',
        },
        {
          type: 'facebook-f',
          to: 'https://www.facebook.com/dibbs.io',
        },
        {
          type: 'twitch',
          to: 'https://www.twitch.tv/dibbsio',
        },
      ];
    },
  }
};
</script>

<style scoped>
.mobile-menu {
  @apply flex flex-col flex-1 text-text-body w-full my-s16 justify-between;
  min-width: 320px;
  height: 100%;
}

.mobile-menu nav {
  max-height: calc(100vh - 180px);
  @apply overflow-auto pr-s16;
}

@media only screen and (max-height: 800px) {
  .mobile-menu nav {
    @apply pr-s16;
  }
}
.mobile-menu-icon {
  @apply object-contain;
  height: 28px;
  width: 28px;
  fill: var(--colors-text-active-2);
}

.mobile-menu__name {
  @apply flex justify-end;
}

.mobile-menu__button {
  @apply flex justify-between items-center cursor-pointer border-b border-border pb-s12 mt-s12;
}

.mobile-menu__option {
  @apply flex flex-col;
}

.mobile-menu__option span {
  @apply mb-s2;
}

.mobile-menu__icon {
  @apply fill-current;
}

.mobile-menu__social {
  @apply w-full pb-s20;
}

.mobile-menu__social-icons {
  @apply flex items-center justify-between;
}

.mobile-menu__theme {
  @apply flex mt-s24 mb-s32;
}
</style>
